import React from 'react'
import clsx from 'clsx'
import style from './index.style.scss'
import { ControlButton } from '../../button'
import { useModalConfirm } from '@/pages/analytics/components/modal/confirm'
import { observer } from 'mobx-react-lite'
import { store } from '@/pages/analytics/store'

interface IControlDeleteProps {
    classes?: {
        root?: string
    }
}

export const ControlDelete: React.FC<IControlDeleteProps> = observer(({ classes, ...rest }) => {
    const cls = { root: clsx(style.root, classes?.root) }

    const { Modal: ModalDeleteConfirm, ...modalDeleteConfirmState } = useModalConfirm()

    const handleDelete = async () => {
        await store.table.handlesRows.delete(store.table.selected, null, true)()

        modalDeleteConfirmState.setOpen(false)

        store.table.selected = []
    }

    return (
        <div className={cls.root} {...rest}>
            <ControlButton icon='delete' label='Удалить' onClick={() => modalDeleteConfirmState.setOpen(true)} />
            <ModalDeleteConfirm
                title={`Вы действительно хотите удалить выбранные строки? (${store.table.selected.join(',')})`}
                onAction={handleDelete}
                {...modalDeleteConfirmState}
            />
        </div>
    )
})
