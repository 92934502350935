import React from 'react'
import clsx from 'clsx'
import style from './index.style.scss'
import { ControlButton } from '../../button'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { store } from '@/pages/analytics/store'

interface IControlArchivesProps {
    classes?: {
        root?: string
    }
}

export const ControlArchives: React.FC<IControlArchivesProps> = observer(({ classes, ...rest }) => {
    const cls = { root: clsx(style.root, classes?.root) }

    const handleArchive = () => {
        const selected = toJS(store.table.selected)

        const rows = [...toJS(store.table.rows)]
            .filter((row) => selected.includes(row.id.value))
            .map((row) => ({
                ...row,
                is_archival: {
                    value: true,
                },
            }))

        store.table.handlesRows.update(rows, null, true)()
    }

    return (
        <div className={cls.root} {...rest}>
            <ControlButton icon='archive' label='Архивировать' onClick={handleArchive} />
        </div>
    )
})
