import React, { useCallback, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { AnalyticsTable } from '../../components/table'
import { AppError } from '../../components/error/app'
import { notifyError } from '@/theme/@deprecated/components/ui'
import { store } from '@/pages/analytics/store'
import { Container } from '@/pages/analytics/components/ui'
import { ApiAnalyticsTypes } from '@/api'
import { useTableSearchParams } from '@/pages/analytics/components/table/hooks/params'
import { useParams } from 'react-router-dom'
import { sleep } from '@/shared'

export const AnalyticsReferenceScreen = observer(() => {
    const params = useParams<{ id: string; reference: string }>()
    const tableSearchParams = useTableSearchParams()

    const scheme = store.app.getSchemeByPath(params.id, params.reference)

    const handleGetTable = useCallback(async () => {
        if (scheme) {
            try {
                /** Первая загрузка */
                if (!store.app.table) {
                    store.app.setState({ loading: true })
                }

                store.table.setState({ loading: true })

                await sleep(300)

                await store.app.controller.get({
                    url: scheme.api,
                    data: tableSearchParams.data,
                })

                store.app.setTitle(scheme.title)
            } catch (err) {
                console.log(err)

                notifyError(err?.message)
            } finally {
                /** Первая загрузка */
                if (store.app.state.loading) {
                    store.app.setState({ loading: false })
                }
                store.table.setState({ loading: false })
            }
        } else {
            // navigate...
            // store.app.schemes[0].items[0]
        }
    }, [scheme, tableSearchParams])

    const handleCreateRows = useCallback(
        (data: ApiAnalyticsTypes['createReference']['req']['data'], cb?: FunctionType, update: boolean = false) =>
            async () => {
                try {
                    await sleep(300)

                    await store.app.controller.post({
                        url: scheme.api,
                        data,
                    })

                    if (update) {
                        await handleGetTable()
                    }

                    cb?.()
                } catch (err) {
                    console.log(err)

                    cb?.(err)
                    notifyError(err?.message)
                }
            },
        [scheme, handleGetTable]
    )

    const handleUpdateRows = useCallback(
        (data: ApiAnalyticsTypes['updateReference']['req']['data'], cb?: FunctionType, update: boolean = false) =>
            async () => {
                try {
                    store.table.setState({ loading: true })

                    await sleep(300)

                    await store.app.controller.update({
                        url: scheme.api,
                        data,
                    })

                    if (update) {
                        await handleGetTable()
                    }

                    cb?.()
                } catch (err) {
                    console.log(err)

                    cb?.(err)
                    notifyError(err?.message)
                } finally {
                    store.table.setState({ loading: false })
                }
            },
        [scheme, handleGetTable]
    )

    const handleDeleteRows = useCallback(
        (data: ApiAnalyticsTypes['deleteReference']['req']['data'], cb?: FunctionType, update: boolean = false) =>
            async () => {
                try {
                    store.table.setState({ loading: true })

                    await sleep(300)

                    await store.app.controller.delete({
                        url: scheme.api,
                        data,
                    })

                    if (update) {
                        await handleGetTable()
                    }

                    cb?.()
                } catch (err) {
                    console.log(err)

                    cb?.(err)
                    notifyError(err?.message)
                } finally {
                    store.table.setState({ loading: false })
                }
            },
        [scheme, handleGetTable]
    )

    /**
     * Подписываемся на обновление через метод handleGetTable,
     * т.к. он подписан на searchParams.
     * (временно на filters, т.к. он подписан на searchParams)
     */
    useEffect(() => {
        handleGetTable()
    }, [handleGetTable])

    return (
        <Container fluid>
            {store.app.table ? (
                <AnalyticsTable
                    schemes={store.app.schemes}
                    scheme={scheme}
                    handlesRows={{
                        get: handleGetTable,
                        create: handleCreateRows,
                        update: handleUpdateRows,
                        delete: handleDeleteRows,
                    }}
                    data={{
                        columns: store.app.table?.columns,
                        rows: store.app.table?.data,
                        size: parseInt(store.app.table?.meta?.per_page) || 0,
                        page: store.app.table?.meta?.current_page - 1 || 0,
                        count: store.app.table?.meta?.total || 0,
                        filters: tableSearchParams?.forTable,
                    }}
                    options={{
                        editPage: {
                            complectations: true,
                            specific_option_id: true,
                            option_package_id: true,
                        },
                        archiveMode: {
                            complectations: true,
                        },
                    }}
                    mode='reference'
                />
            ) : !scheme ? (
                <AppError status={404} />
            ) : (
                store.app.state.loading && <>...Загрузка</>
            )}
        </Container>
    )
})
