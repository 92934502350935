import { TableStore } from './store'

interface Params {
    passport?: string
    isDuplicate?: boolean
}

export const prepareRowData = (data: TableStore['editDataRows'][0][], { passport, isDuplicate }: Params = {}) => {
    return data?.map((data) =>
        Object.keys(data || {}).reduce((acc, curr) => {
            /**
             * Для дубликатов подменяем ключ id на copy_of
             * https://trello.com/c/juoqw5Rk/651
             */
            if (curr === 'id' && isDuplicate) {
                return {
                    ...acc,
                    copy_of: {
                        value: data[curr]?.value,
                    },
                }
            }

            /**
             * Для passport режима для селекта name отправляем ключ value, а не id
             */
            if (data[curr]?.type === 'select' && curr === 'name' && passport) {
                return {
                    ...acc,
                    [passport]: {
                        value: data[curr]?.value,
                    },
                }
            }

            if (data[curr]?.type === 'select') {
                return {
                    ...acc,
                    [curr]: {
                        id: data[curr]?.value,
                    },
                }
            }

            if (data[curr]?.type === 'multiSelect') {
                return {
                    ...acc,
                    [curr]: data[curr]?.value?.map((value) => ({ id: value })) || [],
                }
            }

            if (data[curr]?.type === 'date' && data[curr]?.value) {
                //? Апи принимает дату наоборот. Тире заменяется на точки
                return {
                    ...acc,
                    [curr]: {
                        value: data[curr]?.value.split('-').reverse().join('.'),
                    },
                }
            }

            if (
                data[curr]?.type === 'text' ||
                data[curr]?.type === 'string' ||
                data[curr]?.type === 'number' ||
                data[curr]?.type === 'boolean'
            ) {
                return {
                    ...acc,
                    [curr]: {
                        value: data[curr]?.value,
                    },
                }
            }

            if (data[curr]?.type === 'image') {
                return {
                    ...acc,
                    [curr]: {
                        value: data[curr]?.value?.file || data[curr]?.value,
                    },
                }
            }

            return { ...acc }
        }, {})
    )
}
