import { store } from '@/pages/analytics/store'
import { Button, Container } from '@/theme/@deprecated/components/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import style from '../index.style.scss'
import { messages } from '@/shared'

interface IAppError {
    message?: string
    status?: number | string
}

export const AppError: React.FC<IAppError> = observer(({ message, status }) => {
    const navigate = useNavigate()
    const [_, setSearchParams] = useSearchParams()

    const errorStatus = store.app.state.error?.status || status
    const errorMessage = message || store.app.state.error?.message || messages.errors_https[errorStatus]

    const reset = () => {
        store.app.setState({ error: null, init: false })
        store.app.initialize()

        setSearchParams(new URLSearchParams())
    }

    React.useEffect(() => {
        store.app.setState({ error: { status: errorStatus, message: errorMessage } })
    }, [])

    return (
        <div className={style.root}>
            <Container>
                <div className={style.wrapper}>
                    <p className={style.title}>
                        {messages.errors_https[errorStatus] ? (
                            <>
                                {errorStatus} | {errorMessage}
                            </>
                        ) : (
                            message || messages.errors.app
                        )}
                    </p>
                    {errorStatus === 'app' ||
                        (errorStatus !== 404 && (
                            <Button onClick={reset} type='neutral-error' size='sm' className={style.button}>
                                Перезагрузить приложение
                            </Button>
                        ))}
                    {errorStatus === 404 && (
                        <Button
                            onClick={() => {
                                reset()
                                navigate(`/analytics`)
                            }}
                            type='neutral-error'
                            size='sm'
                            className={style.button}
                        >
                            Перейти на главную
                        </Button>
                    )}
                </div>
            </Container>
        </div>
    )
})
