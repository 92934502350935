import React from 'react'
import { observer } from 'mobx-react-lite'
import { Navigate, Route, Routes } from 'react-router-dom'
import { AppError } from '@/pages/analytics/components/error/app'
import { Layout } from '@/pages/analytics/components/layout'
import { AnalyticsPassportScreen } from '@/pages/analytics/screens/passport'
import { AnalyticsReferenceScreen } from '@/pages/analytics/screens/reference'
import { store } from '@/pages/analytics/store'
import { AnalyticsResidualScreen } from '@/pages/analytics/screens/residual'
import { AnalyticsResidualEditScreen } from '@/pages/analytics/screens/residual/editPage'
import { AnalyticsReferencePageScreen } from './screens/reference/[id]'

export const AnalyticsPage = observer(({ ...rest }) => {
    React.useEffect(() => {
        store.app.initialize()
    }, [])

    const renderNavigate = () => {
        const navigatePath = `/analytics/${store?.app?.schemes[0]?.id}${store?.app?.schemes[0]?.items?.[0].path}`

        return (
            <>
                <Route index element={<Navigate to={navigatePath} />} />
                <Route path='/:id' element={<Navigate to={navigatePath} />} />
            </>
        )
    }

    return (
        <Layout {...rest}>
            <Routes>
                {store.app.schemes.length ? renderNavigate() : null}

                <Route index element={<Navigate to={`reference`} />} />

                <Route path='/residual' element={<AnalyticsResidualScreen />} />
                <Route path='/residual/:id' element={<AnalyticsResidualEditScreen />} />
                <Route path='/:id/:reference' element={<AnalyticsReferenceScreen />} />

                <Route path='/:id/:reference/:passport' element={<AnalyticsPassportScreen />} />
                <Route path='/:id/:reference/:passport/*' element={<Navigate to={`/analytics`} />} />

                <Route path='/:section/:reference/passport/:row' element={<AnalyticsReferencePageScreen />} />

                <Route path='*' element={<AppError status={404} />} />
            </Routes>
        </Layout>
    )
})
