import React from 'react'
import style from './equips.style.scss'
import { Paper } from '../paper'
import { observer } from 'mobx-react-lite'
import { store } from '../../complectation.store'
import { Masonry } from 'react-plock'
import { Empty, Equip } from './ui'
import { Box, LoadingOverlay, ScrollArea } from '@mantine/core'

type EquipsProps = {}

export const Equips: React.FC<EquipsProps> = observer(() => {
    const equips = store.data.equips

    const isExist = !!equips.data.items.length // store.data.complectation.data.has_equips

    const handleOpen = () => {
        equips.load()
    }

    const handleChange = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault()

        const target: any = e.target
        const id = parseInt((target.closest('[data-equip-id]') || e.target)?.dataset?.equipId)

        if (id) {
            equips.toggle(id)
        }
    }

    return (
        isExist && (
            <Paper title='Навесное оборудование' className={style.root} onOpen={handleOpen}>
                <div className={style.inner}>
                    {equips.state.loaded && equips.items.length > 0 && (
                        <ScrollArea.Autosize
                            mah={650}
                            styles={{
                                root: {
                                    paddingRight: 16,
                                },
                            }}
                        >
                            <Masonry
                                items={equips.items}
                                config={{
                                    columns: [1, 2],
                                    gap: [20, 20],
                                    media: [768, 1024],
                                }}
                                render={(item) => (
                                    <div key={item.id} className={style.option}>
                                        <div className={style.grid}>
                                            <Equip item={item} />
                                        </div>
                                    </div>
                                )}
                                onClick={handleChange}
                            />
                        </ScrollArea.Autosize>
                    )}

                    {equips.state.loaded && equips.items.length === 0 && <Box component={Empty} mt={20} />}

                    <LoadingOverlay
                        loaderProps={{ variant: 'bars' }}
                        visible={equips.state.loader}
                        overlayOpacity={0.5}
                    />
                </div>
            </Paper>
        )
    )
})
