import React from 'react'
import { Box, Loader, Select } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { IconChevronDown } from '@tabler/icons-react'
import { styles } from '@/theme/styles'
import { core } from '../../catalog.core'
import { useFilter } from '../../catalog.lib'
import { CatalogFilterModel } from '../../models'

interface FilterSelectProps {
    filter: CatalogFilterModel
}

export const FilterSelect: React.FC<FilterSelectProps> = observer(({ filter, ...rest }) => {
    const [state] = useFilter(filter)
    const value = filter.data.value.length ? filter.data.value[0].value : null
    const ref = React.useRef(null)

    const handleChangeValue = (value: string) => {
        core.changeFilter([value], filter)
    }

    const handleDropdownOpen = () => {
        core.loadFilterValues(filter)
    }

    React.useEffect(() => {
        if (filter.state.loaded && !filter.state.loading) {
            ref.current?.focus?.()
        }
    }, [filter.state.loading, filter.state.loaded])

    return (
        <Box {...rest}>
            <Select
                label={filter.data.label}
                placeholder={filter.data.placeholders[0]}
                value={value}
                data={filter.state.loading && !value ? [] : filter.get.values()}
                searchable
                clearable
                onChange={handleChangeValue}
                rightSection={
                    filter.state.loading ? (
                        <Loader variant='dots' size={20} />
                    ) : value ? null : (
                        <IconChevronDown color={styles.secondary} size={16} />
                    )
                }
                onDropdownOpen={handleDropdownOpen}
                disabled={state.disabled}
                ref={ref}
            />
        </Box>
    )
})
