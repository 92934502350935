import React from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { NavLink } from 'react-router-dom'
import { Icon } from '@/pages/analytics/components/ui'
import { useModalConfirm } from '@/pages/analytics/components/modal/confirm'
import { observer } from 'mobx-react-lite'
import { TableStore } from '@/pages/analytics/store/table/store'
import { toJS } from 'mobx'
import { store } from '@/pages/analytics/store'

interface IAnalyticsTableMenuProps {
    dense?: boolean
    row: TableStore['rows'][0]
}

export const AnalyticsTableMenu: React.FC<IAnalyticsTableMenuProps> = observer(({ dense, row }) => {
    const { Modal: ModalDeleteConfirm, ...modalDeleteConfirmState } = useModalConfirm()

    const ref = React.useRef(null)
    const [isOpen, setIsOpen] = React.useState(false)

    const isEdit = store.table.isEdit(row.id.value)
    const isDuplicate = store.table.isDuplicate(row.id.value)
    const isArhiveMode = store.table.isArchiveMode()
    const isPassportMode = store.table.isMode('passport')
    const isVisible = !!((isPassportMode && store.table.scheme.passport) || !isPassportMode)

    const onClose = () => {
        setIsOpen(false)
    }

    const handleEdit = () => {
        if (!isEdit) {
            store.table.editRows = [...store.table.editRows, row]
        } else {
            store.table.editRows = [...store.table.editRows.filter((editRow) => editRow.id.value !== row.id.value)]
        }

        onClose()
    }

    const handleDuplicate = () => {
        if (!isDuplicate) {
            store.table.duplicatesRows = [...store.table.duplicatesRows, row]
        } else {
            store.table.duplicatesRows = [
                ...store.table.duplicatesRows.filter((duplicateRow) => duplicateRow.id.value !== row.id.value),
            ]
        }

        onClose()
    }

    const handleDelete = async () => {
        await store.table.handlesRows.delete([row.id.value], null, true)()

        modalDeleteConfirmState.setOpen(false)
    }

    const handleArchive = () => {
        store.table.handlesRows.update(
            [
                {
                    ...toJS(row),
                    is_archival: {
                        value: true,
                    },
                },
            ],
            null,
            true
        )()
        onClose()
    }

    return (
        <>
            {isVisible && (
                <>
                    <IconButton ref={ref} onClick={() => setIsOpen(true)} sx={{ p: dense ? 0 : 0.5 }}>
                        <Icon icon='more' />
                    </IconButton>
                    <Menu
                        open={isOpen}
                        anchorEl={ref.current}
                        onClose={() => setIsOpen(false)}
                        PaperProps={{
                            sx: { width: 200, maxWidth: '100%' },
                        }}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        {store.table.isEditPage() ? (
                            <MenuItem
                                onClick={onClose}
                                component={NavLink}
                                to={`/analytics${store.table.scheme.path}/${row.id?.value}`}
                            >
                                <ListItemIcon>
                                    <Icon icon='paste' />
                                </ListItemIcon>
                                <ListItemText primary='Открыть' primaryTypographyProps={{ variant: 'body2' }} />
                            </MenuItem>
                        ) : null}

                        {/* {!isPassportMode && ( */}
                        <MenuItem onClick={handleEdit}>
                            <ListItemIcon>
                                <Icon icon='edit' />
                            </ListItemIcon>
                            <ListItemText
                                primary={isEdit ? 'Отменить' : 'Редактировать'}
                                primaryTypographyProps={{ variant: 'body2' }}
                            />
                        </MenuItem>
                        {/* )} */}

                        {!isPassportMode && (
                            <MenuItem onClick={handleDuplicate}>
                                <ListItemIcon>
                                    <Icon icon='copy' />
                                </ListItemIcon>
                                <ListItemText
                                    primary={isDuplicate ? 'Отменить' : 'Дублировать'}
                                    primaryTypographyProps={{ variant: 'body2' }}
                                />
                            </MenuItem>
                        )}

                        {isArhiveMode ? (
                            <MenuItem onClick={handleArchive}>
                                <ListItemIcon>
                                    <Icon icon='archive' />
                                </ListItemIcon>
                                <ListItemText primary='Архивировать' primaryTypographyProps={{ variant: 'body2' }} />
                            </MenuItem>
                        ) : null}

                        <MenuItem
                            onClick={() => {
                                modalDeleteConfirmState.setOpen(true)
                                onClose()
                            }}
                        >
                            <ListItemIcon>
                                <Icon icon='delete' />
                            </ListItemIcon>
                            <ListItemText primary='Удалить' primaryTypographyProps={{ variant: 'body2' }} />
                        </MenuItem>
                    </Menu>
                </>
            )}

            <ModalDeleteConfirm
                title={`Вы действительно хотите удалить выбранную строку? (${row.id.value}:${row.name?.value})`}
                onAction={handleDelete}
                {...modalDeleteConfirmState}
            />
        </>
    )
})
