import React from 'react'
import { Box } from '@mantine/core'
import { styles } from '@/theme/styles'
import { IconDatabaseOff } from '@tabler/icons-react'

export const Empty = () => {
    return (
        <Box
            mx={'auto'}
            sx={(theme) => ({
                background: theme.fn.lighten(styles.tertiary, 0.6),
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            })}
            w={100}
            h={100}
        >
            <IconDatabaseOff stroke={1.2} size={60} color={styles.tertiary} />
        </Box>
    )
}
