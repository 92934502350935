import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'
import {
    AnalyticsReferenceReadResponseColumn,
    AnalyticsReferenceReadResponseData,
    AnalyticsReferenceReadResponseLinks,
    AnalyticsReferenceReadResponseMeta,
} from './interfaces'
import { uniqueByKey } from '@/shared/lib/array'

export type AnalyticsReferenceReadRequest = {
    url: string
    data?: ObjectType & {
        'page[number]': string
        'page[size]': string
        'filters[name]'?: string
        'sorts[name]'?: string
        is_archival?: boolean
        search?: string
        minimal_column_set?: boolean
    }
}
export type AnalyticsReferenceReadResponse = {
    data: AnalyticsReferenceReadResponseData[]
    links: AnalyticsReferenceReadResponseLinks
    meta: AnalyticsReferenceReadResponseMeta
    columns: AnalyticsReferenceReadResponseColumn[]
    success: boolean
}

export const AnalyticsReferenceRead = async (req: AnalyticsReferenceReadRequest) => {
    try {
        const response: ApiResponse<AnalyticsReferenceReadResponse> =
            await apiConfig.fetch.ilsa<AnalyticsReferenceReadResponse>({
                url: req.url,
                config: {
                    method: 'GET',
                    params: req.data,
                },
            })

        if (!response?.data?.success) {
            throw Error('Не удалось получить данные таблицы аналитика')
        }

        const payload = {
            ...response.data,
            data: Array.isArray(response.data.data)
                ? response.data.data.map((row) => {
                      Object.keys(row).forEach((key) => {
                          if (Array.isArray(row[key])) {
                              row[key] = uniqueByKey(row[key], 'id')
                          }
                      })

                      return row
                  })
                : response.data.data,
            columns: response.data.columns.map((column) => {
                if (column.label.toLowerCase().includes('дата')) {
                    column.type = 'date'
                }

                column.editable = column.editable === false ? false : true

                /**
                 * FIX: Апи для equips отдает зависимости не правильно
                 * Нужен массив
                 */
                if (column.dependencies && !Array.isArray(column.dependencies)) {
                    column.dependencies = [column.dependencies]
                }

                return column
            }),
        }

        return payload
    } catch (err: any) {
        throwAdapterError(err, 'AnalyticsReferenceRead')
    }
}
