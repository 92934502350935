import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import TabContext from '@mui/lab/TabContext'
import React from 'react'
import style from './index.style.scss'
import { Container } from '@/pages/analytics/components/ui'
import { RowTable } from './table'
import { useSearchParams } from 'react-router-dom'
import { ApiAnalyticsTypes } from '@/api'

interface RowTabsProps {
    scheme: ApiAnalyticsTypes['getScheme']['scheme']
}

// @ts-ignore
export const RowTabs: React.FC<RowTabsProps> = ({ title, scheme, ...rest }) => {
    const [searchParams, setSearchParams] = useSearchParams()

    const [table, setTable] = React.useState(
        scheme.items.find((item) => item.id === searchParams.get('reference')) || scheme.items[0]
    )

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        const params = new URLSearchParams()
        const newTable = scheme.items.find((item) => item.id === newValue)

        setTable(newTable)

        params.set('reference', newTable.id)

        setSearchParams(params)
    }

    return (
        <div className={style.root} {...rest}>
            <TabContext value={table.id}>
                <div className={style.head}>
                    <Container className={style.headWrapper}>
                        <div className={style.title}>{title}</div>
                        <Tabs value={table.id} onChange={handleChange}>
                            {scheme.items.map((item) => (
                                <Tab key={item.id} value={item.id} label={item.title} />
                            ))}
                        </Tabs>
                    </Container>
                </div>
                <Container>
                    <RowTable scheme={table} />
                </Container>
            </TabContext>
        </div>
    )
}
