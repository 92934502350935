import { AppError } from '@/pages/analytics/components/error/app'
import { AnalyticsTable } from '@/pages/analytics/components/table'
import { store } from '@/pages/analytics/store'
import { notifyError } from '@/theme/@deprecated/components/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'
import style from './index.style.scss'
import { ApiAnalyticsTypes, service } from '@/api'
import { useTableSearchParams } from '@/pages/analytics/components/table/hooks/params'
import { useAnalyticsPassportScreenParams } from '../..'
import { Empty } from '@/pages/analytics/components/table/components/empty'

interface RowTableProps {
    scheme: ApiAnalyticsTypes['getScheme']['scheme']
}

export const RowTable: React.FC<RowTableProps> = observer(({ scheme, ...rest }) => {
    const params = useAnalyticsPassportScreenParams()

    const { reference, passport } = params

    const tableSearchParams = useTableSearchParams()

    const url = `/${reference}/${passport}${scheme.api}`

    const handleGetTable = React.useCallback(async () => {
        if (scheme) {
            try {
                store.table.setState({ loading: true })

                await store.app.controller.get({
                    url: url,
                    data: tableSearchParams.data,
                })
            } catch (err) {
                console.log(err)

                store.table.rows = []
                store.table.columns = []
                store.table.error = err?.message
            } finally {
                store.table.setState({ loading: false })
            }
        }
    }, [scheme, tableSearchParams])

    const handleCreateRows = React.useCallback(
        (data: ApiAnalyticsTypes['createReference']['req']['data'], cb?: FunctionType, update: boolean = false) =>
            async () => {
                try {
                    store.table.setState({ loading: true })

                    await store.app.controller.post({
                        url: url,
                        data,
                    })

                    if (update) {
                        await handleGetTable()
                    }

                    cb?.()
                } catch (err) {
                    console.log(err)

                    cb?.(err)
                    notifyError(err?.message)
                } finally {
                    store.table.setState({ loading: false })
                }
            },
        [scheme, handleGetTable]
    )

    const handleUpdateRows = React.useCallback(
        (data: ApiAnalyticsTypes['updateReference']['req']['data'], cb?: FunctionType, update: boolean = false) =>
            async () => {
                try {
                    store.table.setState({ loading: true })

                    await store.app.controller.update({
                        url: url,
                        data,
                    })

                    if (update) {
                        await handleGetTable()
                    }

                    cb?.()
                } catch (err) {
                    console.log(err)

                    cb?.(err)
                    notifyError(err?.message)
                } finally {
                    store.table.setState({ loading: false })
                }
            },
        [scheme, handleGetTable]
    )

    const handleDeleteRows = React.useCallback(
        (data: ApiAnalyticsTypes['deleteReference']['req']['data'], cb?: FunctionType, update: boolean = false) =>
            async () => {
                try {
                    store.table.setState({ loading: true })

                    await store.app.controller.delete({
                        url: url,
                        data,
                    })

                    if (update) {
                        await handleGetTable()
                    }

                    cb?.()
                } catch (err) {
                    console.log(err)

                    cb?.(err)
                    notifyError(err?.message)
                } finally {
                    store.table.setState({ loading: false })
                }
            },
        [scheme, handleGetTable]
    )

    const handleUploadImage: typeof store.table.handlesRows.uploadImage = React.useCallback(async (data, cb) => {
        try {
            // store.table.setState({ loading: true })

            await service.analytics.reference_upload_image(data)

            cb?.()
        } catch (err) {
            console.log(err)

            cb?.(err)
            notifyError(err?.message)
        } finally {
            // store.table.setState({ loading: false })
        }
    }, [])

    React.useEffect(() => {
        handleGetTable()
    }, [handleGetTable])

    return (
        <div className={style.root} {...rest}>
            {store.app.table ? (
                <AnalyticsTable
                    schemes={store.app.schemes}
                    scheme={scheme}
                    handlesRows={{
                        get: handleGetTable,
                        create: handleCreateRows,
                        update: handleUpdateRows,
                        delete: handleDeleteRows,
                        uploadImage: handleUploadImage,
                    }}
                    data={{
                        columns: store.app.table.columns,
                        rows: store.app.table.data,
                        size: parseInt(store.app.table.meta.per_page),
                        page: store.app.table.meta.current_page - 1,
                        count: store.app.table.meta.total,
                        filters: tableSearchParams.forTable,
                    }}
                    options={{
                        editPage: {
                            complectations: true,
                        },
                        archiveMode: {
                            complectations: true,
                        },
                    }}
                    mode='passport'
                    selectRequestData={{
                        'filters[brand_id]': store.passport.data.find((el) => el.id === 'brand_id').value.selected.id,
                    }}
                />
            ) : !scheme ? (
                <AppError status={404} />
            ) : (
                (store.app.state.loading && <>...Загрузка</>) || <Empty error />
            )}
        </div>
    )
})
