import React from 'react'
import { Badge, Box, Button, Drawer, LoadingOverlay, ScrollArea, TextInput, Tooltip } from '@mantine/core'
import { IconChevronDown, IconX } from '@tabler/icons-react'
import { store } from '../../complectation.store'
import { observer } from 'mobx-react-lite'
import { useDisclosure } from '@mantine/hooks'
import { core } from '../../complectation.core'
import { number } from '@/shared'
import { Color } from '../../complectation.types'
import { ComplectationOptionModel, ComplectationPackageModel } from '../../models'
import style from './menu.style.scss'
import { styles } from '@/theme/styles'
import { useNavigate } from 'react-router-dom'

const MenuPopup = observer((props: { open: () => void }) => {
    const { open } = props
    const count = store.menu_count
    const { amount } = store.rrc_amount
    const complectation = store.data.complectation

    const handleOpen = () => {
        open()
    }

    return (
        <div className={style.menu}>
            <div className={style.menu_title}>
                {complectation.price_type} с опциями {number.format(amount)} ₽
            </div>
            <div className={style.menu_action} onClick={handleOpen}>
                <div>Выбранные опции</div>
                <Badge className={style.menu_badge}>{count}</Badge>
                <IconChevronDown className={style.menu_arrow} />
            </div>
        </div>
    )
})

const MenuDrawer = observer((props: { opened: boolean; close: () => void }) => {
    const { opened, close } = props

    const navigate = useNavigate()

    const count = store.menu_count
    const complectation = store.data.complectation
    const default_name = store.data.default_data.name.value
    const carstock_parameters = complectation.carstock_parameters
    const additional_equipment = store.additional_equipment

    const { amount, color, packages, options, equips } = store.rrc_amount

    const is_exist_configuration = !!store.data.default_data.id?.value

    // const handleDeleteColor = (color: Color) => () => {
    //     complectation.setColor(null)
    // }

    const handleDeletePackage = (pkg: ComplectationPackageModel) => () => {
        pkg.unselect()
    }

    const handleDeleteOption = (option: ComplectationOptionModel['data']['items'][0]) => () => {
        core.toggleSelectedOption(option.id)
    }

    const handleDeleteEquip = (id: number) => () => {
        store.data.equips.toggle(id)
    }

    const handleSave = (e) => {
        const is_new = !!e.currentTarget.dataset.new

        if (is_exist_configuration && !is_new) {
            core.edit(() => {
                close()
            })
        } else {
            core.save(() => {
                navigate('/configurations/')
            })
        }
    }

    const handleCarstock = () => {
        const url = `/carstock/?data={"filters":{"1101":[${carstock_parameters.brand_id}],"1102":[${carstock_parameters.model_id}],"1103":[${carstock_parameters.version_id}]}}`

        window.open(url, '_blank')
    }

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value

        store.setData({ name: value })
    }

    // React.useEffect(() => {
    //     if (count < 1) {
    //         close()
    //     }
    // }, [count])

    return (
        <Drawer
            zIndex={1000}
            opened={opened}
            onClose={close}
            position='right'
            overlayProps={{ opacity: 0.5, blur: 4 }}
            withCloseButton={false}
            size={526}
            className={style.drawer}
            trapFocus={false}
        >
            <LoadingOverlay visible={store.state.saving} radius={24} />

            <div className={style.drawer_inner}>
                <div className={style.drawer_close} onClick={close}>
                    <IconX />

                    <span>Закрыть</span>
                </div>

                <TextInput
                    value={store.data.name}
                    label={'Название конфигурации'}
                    mt={18}
                    mx={32}
                    styles={{
                        input: {
                            borderTop: 0,
                            borderLeft: 0,
                            borderRight: 0,
                            borderRadius: 0,
                            padding: 0,
                        },
                        label: {
                            color: styles.secondary,
                            fontSize: 14,
                            fontWeight: 400,
                            marginBottom: 0,
                            lineHeight: 1,
                        },
                    }}
                    onChange={handleChangeName}
                />

                <div className={style.drawer_complectation}>
                    <div className={style.drawer_complectation_title}>{complectation.data.header.title}</div>
                    <div className={style.drawer_complectation_label}>{complectation.data.header.subtitle}</div>
                </div>

                <div className={style.drawer_rrc}>
                    <div className={style.drawer_rrc_base}>
                        <span>Базовая {complectation.price_type}:</span>
                        <span>{complectation.price} ₽</span>
                    </div>

                    {complectation.discount && (
                        <div className={style.drawer_rrc_discount}>
                            <span>{complectation.discount.label}:</span>
                            <span>{complectation.discount.value}</span>
                        </div>
                    )}

                    <div className={style.drawer_rrc_label}>
                        {complectation.price_type} с учетом опций и доп. оборудования дилера
                    </div>

                    <div className={style.drawer_rrc_value}>{number.format(amount)} ₽</div>
                </div>

                <div className={style.drawer_options}>
                    <div className={style.drawer_options_title}>
                        <span>Выбранные опции</span>

                        <Badge className={style.drawer_options_count}>{count}</Badge>
                    </div>

                    <ScrollArea.Autosize mah={'calc(100% - 70px)'}>
                        <div className={style.drawer_options_list}>
                            {!!additional_equipment && (
                                <div className={style.drawer_options_list_item}>
                                    <div className={style.drawer_options_list_item_label}>
                                        Доп. оборудование - {additional_equipment.label}
                                    </div>

                                    <div className={style.drawer_options_list_item_value}>
                                        + {number.format(additional_equipment.value)} ₽
                                    </div>
                                </div>
                            )}

                            {color && (
                                <div className={style.drawer_options_list_item}>
                                    <div className={style.drawer_options_list_item_label}>{color.name}</div>

                                    <div className={style.drawer_options_list_item_value}>
                                        + {number.format(color.price)} ₽
                                    </div>

                                    {/* <IconX
                                        className={style.drawer_options_list_item_action}
                                        onClick={handleDeleteColor(color)}
                                    /> */}
                                </div>
                            )}

                            {equips.map((equip) => (
                                <div key={equip.id} className={style.drawer_options_list_item}>
                                    <div className={style.drawer_options_list_item_label}>{equip.name}</div>

                                    <div className={style.drawer_options_list_item_value}>
                                        + {number.format(equip.price)} ₽
                                    </div>

                                    <IconX
                                        className={style.drawer_options_list_item_action}
                                        onClick={handleDeleteEquip(equip.id)}
                                    />
                                </div>
                            ))}

                            {packages.map((pkg) => (
                                <div key={pkg.data.id} className={style.drawer_options_list_item}>
                                    <div className={style.drawer_options_list_item_label}>{pkg.data.name}</div>

                                    <div className={style.drawer_options_list_item_value}>
                                        + {number.format(pkg.data.price)} ₽
                                    </div>

                                    <IconX
                                        className={style.drawer_options_list_item_action}
                                        onClick={handleDeletePackage(pkg)}
                                    />
                                </div>
                            ))}

                            {options.map((option) => (
                                <div key={option.id} className={style.drawer_options_list_item}>
                                    <div className={style.drawer_options_list_item_label}>{option.value}</div>

                                    <div className={style.drawer_options_list_item_value}>
                                        + {number.format(option.price)} ₽
                                    </div>

                                    <IconX
                                        className={style.drawer_options_list_item_action}
                                        onClick={handleDeleteOption(option)}
                                    />
                                </div>
                            ))}
                        </div>
                    </ScrollArea.Autosize>

                    <Button className={style.drawer_action} variant='light' onClick={handleSave}>
                        {is_exist_configuration ? 'Обновить' : 'Сохранить'} конфигурацию
                    </Button>

                    {is_exist_configuration && (
                        <Tooltip
                            multiline
                            width={295}
                            label={'Создать новую конфигурацию можно, изменив название'}
                            offset={-10}
                            withArrow
                            position='top'
                            sx={{ display: default_name === store.data.name ? 'block' : 'none' }}
                        >
                            <Box display='inline-block'>
                                <Button
                                    className={style.drawer_action_new}
                                    variant='light'
                                    data-new='true'
                                    onClick={handleSave}
                                    disabled={default_name === store.data.name}
                                >
                                    Создать новую конфигурацию
                                </Button>
                            </Box>
                        </Tooltip>
                    )}

                    {!!(complectation.carstock_count && carstock_parameters) && (
                        <Button className={style.drawer_action_carstock} variant='outline' onClick={handleCarstock}>
                            {complectation.carstock_count.label} {complectation.carstock_count.value}
                        </Button>
                    )}
                </div>
            </div>
        </Drawer>
    )
})

export const Menu: React.FC = () => {
    const [opened, { open, close }] = useDisclosure(false)

    return (
        <>
            <MenuPopup open={open} />
            <MenuDrawer opened={opened} close={close} />
        </>
    )
}
