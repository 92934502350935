import { apiConfig } from '@/api/api.config'
import { throwAdapterError } from '@/shared'

type CatalogComplectationEquipsRequest = {
    data: {
        id: string
    }
}

type CatalogComplectationEquipsResponse = {
    data: {
        id: {
            value: number
        }
        type: {
            value: string
        }
        brand: {
            value: string
        }
        model: {
            value: string
        }
        name: {
            value: string
        }
        price: {
            value: string
        }
        basic: {
            value: boolean
        }
    }[]
}

export const CatalogComplectationEquips = async (
    req: CatalogComplectationEquipsRequest,
    controller?: AbortController
) => {
    try {
        const { data } = await apiConfig.fetch.ilsa<CatalogComplectationEquipsResponse>({
            url: '/equips',

            config: {
                method: 'GET',
                signal: controller?.signal,
                cache: true,
                params: {
                    'page[number]': 1,
                    'page[size]': 999,
                    // 'filters[is_archival]': false,
                    'filters[complectation_id]': req.data.id,
                },
            },
        })

        return { items: data }
    } catch (err: any) {
        throwAdapterError(err, 'CatalogComplectationEquips')
    }
}
