import React from 'react'
import style from './index.style.scss'
import { RowFields } from './fields'
import { RowTabs } from './tabs'
import { observer } from 'mobx-react-lite'
import { store } from '@/pages/analytics/store'
import { useParams } from 'react-router-dom'

export const useAnalyticsPassportScreenParams = () => useParams<{ id: string; reference: string; passport: string }>()

export const AnalyticsPassportScreen: React.FC = observer(({ ...rest }) => {
    const params = useAnalyticsPassportScreenParams()

    const { id, reference, passport } = params

    const scheme = store.app.getSchemeByPath(id, reference)

    const fieldScheme = { path: `${scheme.path}/${passport}`, reference }

    const { data, getData } = store.passport

    React.useEffect(() => {
        getData({
            url: fieldScheme.path,
            reference: fieldScheme.reference,
        })
    }, [getData])

    React.useEffect(() => {
        return () => {
            store.passport.setData(null)
        }
    }, [])

    return (
        <div className={style.root} {...rest}>
            {data ? (
                <>
                    <RowFields data={data} scheme={fieldScheme} />

                    <RowTabs
                        // @ts-ignore
                        title={data.find((item) => item?.id)?.value || ''}
                        scheme={scheme}
                    />
                </>
            ) : (
                store.app.state.loading && <div style={{ padding: '0 24px' }}>...Загрузка</div>
            )}
        </div>
    )
})
