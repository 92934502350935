import React, { useCallback, useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { store } from '@/pages/analytics/store'
import { Container } from '@/pages/analytics/components/ui'
import { useTableSearchParams } from '@/pages/analytics/components/table/hooks/params'
import { useParams } from 'react-router-dom'
import { notifyError } from '@/theme/@deprecated/components/ui'
import { AnalyticsTable } from '@analytics/components/table'
import { sleep } from '@/shared'
import { Empty } from '@analytics/components/table/components/empty'
import { ApiAnalyticsTypes } from '@/api'

export type AnalyticsReferencePageScreenParams = {
    /**
     * Секция справочника (ID раскрываемой вкладки)
     */
    section: string

    /**
     * ID справочника (мэтчиться с ID из схемы)
     */
    reference: string

    /**
     * ID строки из таблицы справочника
     */
    row: string
}

export const AnalyticsReferencePageScreen = observer(() => {
    const params = useParams<AnalyticsReferencePageScreenParams>()
    const searchParams = useTableSearchParams()

    const scheme = store.app.getSchemeByPath(params.section, params.reference)
    const apiUrl = `${scheme.api}/${params.row}/paramsvalues`

    const data = useMemo(
        () => ({
            columns: store.app.table?.columns,
            rows: store.app.table?.data,
            size: parseInt(store.app.table?.meta?.per_page) || 0,
            page: store.app.table?.meta?.current_page - 1 || 0,
            count: store.app.table?.meta?.total || 0,
            filters: searchParams?.forTable,
        }),
        [store.app.table, searchParams.forTable]
    )

    const handleGetTable = useCallback(async () => {
        if (scheme) {
            try {
                store.app.setState({ loading: true })

                await sleep(300)

                await store.app.controller.get({
                    url: apiUrl,
                    data: searchParams.data,
                })
            } catch (err) {
                console.log(err)

                notifyError(err?.message)
            } finally {
                store.app.setState({ loading: false })
            }
        }
    }, [scheme, searchParams])

    const handleCreateRows = useCallback(
        (data: ApiAnalyticsTypes['createReference']['req']['data'], cb?: FunctionType, update: boolean = false) =>
            async () => {
                try {
                    await sleep(300)

                    await store.app.controller.post({
                        url: apiUrl,
                        data,
                    })

                    if (update) {
                        await handleGetTable()
                    }

                    cb?.()
                } catch (err) {
                    console.log(err)

                    cb?.(err)
                    notifyError(err?.message)
                }
            },
        [scheme, handleGetTable]
    )

    const handleUpdateRows = useCallback(
        (data: ApiAnalyticsTypes['updateReference']['req']['data'], cb?: FunctionType, update: boolean = false) =>
            async () => {
                try {
                    store.table.setState({ loading: true })

                    await sleep(300)

                    await store.app.controller.update({
                        url: apiUrl,
                        data,
                    })

                    if (update) {
                        await handleGetTable()
                    }

                    cb?.()
                } catch (err) {
                    console.log(err)

                    cb?.(err)
                    notifyError(err?.message)
                } finally {
                    store.table.setState({ loading: false })
                }
            },
        [scheme, handleGetTable]
    )

    const handleDeleteRows = useCallback(
        (data: ApiAnalyticsTypes['deleteReference']['req']['data'], cb?: FunctionType, update: boolean = false) =>
            async () => {
                try {
                    store.table.setState({ loading: true })

                    await sleep(300)

                    await store.app.controller.delete({
                        url: apiUrl,
                        data,
                    })

                    if (update) {
                        await handleGetTable()
                    }

                    cb?.()
                } catch (err) {
                    console.log(err)

                    cb?.(err)
                    notifyError(err?.message)
                } finally {
                    store.table.setState({ loading: false })
                }
            },
        [scheme, handleGetTable]
    )

    useEffect(() => {
        store.app.setTitle(`${scheme.title} - ${params.row}`)
    }, [])

    useEffect(() => {
        handleGetTable()
    }, [scheme, searchParams])

    return (
        <Container fluid>
            <div style={{ marginBottom: 10 }} hidden>
                <p style={{ marginBottom: 10 }}>Страница паспорта</p>

                <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                    <p>ID Секции: {params.section}</p>
                    <p>ID Справочника: {params.reference}</p>
                    <p>ID Строки: {params.row}</p>
                </div>
            </div>

            {!store.app.table && store.app.state.loading && scheme && <p>...Загрузка</p>}

            {store.app.table && (
                <AnalyticsTable
                    schemes={store.app.schemes}
                    scheme={scheme}
                    handlesRows={{
                        get: handleGetTable,
                        create: handleCreateRows,
                        update: handleUpdateRows,
                        delete: handleDeleteRows,
                    }}
                    data={data}
                    mode='page'
                />
            )}

            {!store.app.table && !store.app.state.loading && scheme && <Empty />}
        </Container>
    )
})
