import { service } from '@/api'
import { merge } from '@/shared'
import { BaseModel } from '@/shared/core/model'

interface ModelProps {
    /** ID комплекатции */
    complectation?: string

    /** Список навесного оборудования */
    items?: Item[]

    service?: {
        getItems?: typeof service.catalog.complectationEquips
        getItemsController?: AbortController
    }
}

interface Item {
    id: number
    name: string
    price: number
    type: string
    selected: boolean
    basic: boolean
}

export class ComplectationEquipsModel extends BaseModel<ModelProps, ComplectationEquipsModel, ModelProps['service']> {
    static defaultData: Omit<ModelProps, 'service'> = {
        items: [],
        complectation: null,
    }

    service: ModelProps['service'] = {
        getItems: service.catalog.complectationEquips,
        getItemsController: new AbortController(),
    }

    constructor(props?: ModelProps) {
        super(merge(ComplectationEquipsModel.defaultData, props), ComplectationEquipsModel)
    }

    get items() {
        const items = this.data.items.reduce((acc, curr) => {
            return acc.concat([
                {
                    ...curr,
                    name: `${curr.type}. ${curr.name}`,
                },
            ])
        }, [] as ModelProps['items'])

        return items
    }

    toggle(id: number, value?: boolean) {
        this.data.items.forEach((item) => {
            if (item.id !== id) {
                item.selected = false
            } else {
                item.selected = value === undefined ? !item.selected : value
            }
        })
    }

    getById(id: number) {
        return this.data.items.find((item) => item.id === id)
    }

    getSelected() {
        const filtered = this.data.items.filter((item) => item.selected)

        return filtered
    }

    getSelectedIds() {
        const items = this.getSelected()

        return items.map((item) => ({ id: item.id }))
    }

    async load() {
        if (this.state.loader || this.state.loaded || !this.service.getItems || !this.data.complectation) return

        this.setState({
            loader: true,
        })

        try {
            const { items: result } = await this.service.getItems({ data: { id: this.data.complectation } })

            const items: Item[] = result.data.map((item) => {
                return {
                    id: item.id.value,
                    name: item.name.value,
                    price: +item.price.value || 0,
                    type: item.type.value,
                    selected: item.basic.value,
                    basic: item.basic.value,
                }
            })

            this.setData({
                items,
            })

            this.setState({
                loader: false,
            })
        } catch (err) {
            console.log(err)

            this.setData({
                items: [],
            })
        } finally {
            this.setState({
                loader: false,
                loaded: true,
            })
        }
    }
}
